






import { defineComponent } from "@vue/composition-api";
import { cx } from "@/lib/style/cva.config";

export default defineComponent({
	setup() {
		return { cx };
	},
});
