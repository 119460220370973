













import { defineComponent } from "@vue/composition-api";
import { cardVariants } from "@/lib/pfg/components/card";
import { cx } from "@/lib/style/cva.config";

export default defineComponent({
	setup() {
		return { cx, cardVariants };
	},
});
