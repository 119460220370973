var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"flex flex-col gap-8"},[_c('div',{staticClass:"flex items-center justify-between gap-4"},[_c('h2',{staticClass:"m-0 text-2xl font-bold normal-case text-black"},[_vm._v("Logs per week")]),_c('router-link',{class:_vm.buttonVariants({ color: 'legacy', class: 'gap-2' }),attrs:{"to":{
				name: 'Gebruiker belpoging',
				params: { id: String(_vm.memberId) },
			}}},[_vm._v(" Belpoging vastleggen "),_c('pfg-icon',{attrs:{"icon-id":"icon_plus"}})],1)],1),_c('div',{staticClass:"flex flex-col-reverse gap-6"},_vm._l((Object.entries(_vm.items)),function(ref){
			var year = ref[0];
			var yearItems = ref[1];
return _c('div',{key:year,staticClass:"flex flex-col gap-6"},[_c('h3',{staticClass:"m-0 flex items-center justify-between gap-4 text-xl font-bold normal-case text-black"},[_vm._v(" "+_vm._s(year)+" "),_c('hr',{staticClass:"m-0 flex-1"})]),_c('div',{staticClass:"flex flex-col-reverse gap-4"},_vm._l((Object.entries(yearItems)),function(ref){
			var week = ref[0];
			var weekItems = ref[1];
return _c('div',{key:week,staticClass:"flex flex-col gap-4"},[_c('h4',{staticClass:"m-0 flex items-center justify-between gap-4 text-lg normal-case text-black"},[_vm._v(" Week "+_vm._s(week)+" "),_c('hr',{staticClass:"m-0 flex-1"})]),_c('div',{staticClass:"flex flex-col gap-2"},_vm._l((Object.entries(weekItems)),function(ref){
			var day = ref[0];
			var dayItems = ref[1];
return _c('div',{key:day,staticClass:"flex flex-col gap-2"},[_c('h5',{staticClass:"m-0 text-xs font-normal normal-case text-black"},[_c('time',{attrs:{"datetime":day}},[_vm._v(" "+_vm._s(new Date(day).toLocaleDateString("nl", { weekday: "long", day: "numeric", month: "long", year: "numeric", }))+" ")])]),_c('div',{staticClass:"flex flex-col gap-2"},_vm._l((Object.entries(dayItems)),function(ref){
			var time = ref[0];
			var logs = ref[1];
return _c('div',{key:time,staticClass:"flex flex-col gap-2"},_vm._l((logs),function(log){return _c('div',{key:((log.type) + "_" + (log.timestamp)),staticClass:"contents"},[_c('Log',{attrs:{"day":day,"time":time,"log":log}})],1)}),0)}),0)])}),0)])}),0)])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }