
















































































import { type PropType, computed, defineComponent } from "@vue/composition-api";
import { useQuery } from "@tanstack/vue-query";
import { addDays, formatISO, getWeek, getYear, subYears } from "date-fns";
import { coreApi } from "@/lib/backend";
import type { IomodelsCoachappLidBezoek } from "@/lib/backend/club.api";
import type { PaginatedActivityLogViewModel } from "@/lib/backend/core.api";
import { buttonVariants } from "@/lib/pfg/components/button";
import Log from "./Log/index.vue";

type Years = Record<string, Weeks>;
type Weeks = Record<string, Days>;
type Days = Record<string, Time>;
type Time = Record<string, PaginatedActivityLogViewModel["data"]>;

export default defineComponent({
	components: { Log },
	props: {
		fromDate: {
			type: String,
			default: () => subYears(new Date(), 1).toISOString(),
			required: false,
		},
		toDate: {
			type: String,
			default: () => addDays(new Date(), 1).toISOString(),
			required: false,
		},
		memberId: {
			type: Number,
			required: true,
		},
		visits: {
			type: Array as PropType<IomodelsCoachappLidBezoek[]>,
			default: (): IomodelsCoachappLidBezoek[] => [],
		},
	},
	setup(props) {
		const queryLogs = useQuery({
			queryKey: ["member", props.memberId, "logs", props.fromDate, props.toDate] as const,
			queryFn: async (context) =>
				await coreApi.members
					.memberActivityLogControllerGetActivityLogs(
						context.queryKey[1],
						{
							"where[timestamp][gte]": context.queryKey[3],
							"where[timestamp][lt]": context.queryKey[4],
						},
						{ signal: context.signal },
					)
					.then((response) => response.data),
		});

		const items = computed(
			() =>
				queryLogs.data.value?.data.reduce<Years>((acc, log) => {
					const date = new Date(log.timestamp);
					const year = getYear(date);
					const week = getWeek(date);
					const fullDate = formatISO(date, { representation: "date" });
					const time = formatISO(date, { representation: "time" });

					return {
						...(acc || {}),
						[year]: {
							...(acc?.[year] || {}),
							[week]: {
								...(acc?.[year]?.[week] || {}),
								[fullDate]: {
									[time]: [...(acc?.[year]?.[week]?.[fullDate]?.[time] || []), log],
									...(acc?.[year]?.[week]?.[fullDate] || {}),
								},
							},
						},
					};
				}, {}) ?? {},
		);

		return { queryLogs, items, buttonVariants };
	},
});
